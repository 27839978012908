import React from 'react';

import { graphql } from 'gatsby';
import TextBlock from 'components/textBlock';
import ProductsGrid from 'components/productsGrid';

const TemplateProdutos = (props) => {
  //PROPS
  const {
    data: {
      allWpProduto: { nodes },
      page: {
        products: { introText },
      },
    },
  } = props;
  //PROPS

  return (
    <>
      <TextBlock data={introText} />
      <ProductsGrid data={nodes} hasFilters />
    </>
  );
};

export const query = graphql`
  query Produtos($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      products {
        introText
      }
    }
    allWpProduto {
      nodes {
        slug
        seo {
          slug
        }
        product {
          text
          title
          imagem {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1, layout: CONSTRAINED, width: 300)
              }
            }
          }
        }
      }
    }
  }
`;

export default TemplateProdutos;
